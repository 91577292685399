export const updateSessionObject = (key, object) => {
  if (key) {
    window.sessionStorage.setItem(key, JSON.stringify(object));
  }
};

export const getSessionObject = (key) => {
  let object = null;

  if (key) {
    const serialized = window.sessionStorage.getItem(key);

    if (serialized) {
      object = JSON.parse(serialized);
    }
  }

  return object;
};
