import * as firestore from "@/util/firestore";
import { firestoreAction } from "vuexfire";
import signalR from "@/util/signalR";
import {
  ACTIONS,
  MUTATIONS,
  EMPTY_INBOX_MESSAGES,
  INBOX_TYPES,
  GETTERS,
  INBOX_TYPE_SOURCES,
  INBOX_SOURCES,
  CONVERSATION_FILTERS,
} from "@/util/constants";
import api from "@/util/api";
import _ from "lodash";
import moment from "moment";
import "moment-timezone";
import { vuexfireMutations } from "vuexfire";
import mapKeysDeep from "map-keys-deep-lodash";
import { decapitalize } from "@/util/filters";
import {
  arrayToHashMap,
  hashGet,
  hashValues,
  hashMerge,
  hashCloneDeep,
} from "@/util/hash";

let unsubscribeConversation;
let conversationListeners = [];
let signalRTollFreeInboxConnection;
let signalRInboxConnection;
let signalRMessagesConnection;
let signalRMessageGroup;
let conversationLimit = 20;
let creditBalanceTimeout;

function lockedByUser(c, id) {
  return c && c.state && c.state.lockedForStaffId === id;
}

function formatSignalRConversation(c) {
  c.contactData = c.contact;
  c.id = c.contact.id;
  c.contact = c.contact.displayText;
  if (c.errorMessage) {
    c.error = c.errorMessage;
  }
  return c;
}

function getActiveInboxFilterKey(activeInbox) {
  if (activeInbox.type === INBOX_TYPES.PERSONAL) {
    return `${INBOX_TYPES.PERSONAL}-${activeInbox.userId}`;
  }
  if (activeInbox.type === INBOX_TYPES.GROUP) {
    return `${INBOX_TYPES.GROUP}-${activeInbox.groupId}`;
  }
  if (activeInbox.type === INBOX_TYPES.HIGH_SPEED) {
    return `${INBOX_TYPES.HIGH_SPEED}-${activeInbox.highSpeedId}`;
  }
}

export default {
  state: () => ({
    inboxFilters: [],
    activeInbox: null,
    isArchivedView: null,
    isInboxView: null,
    inboxStatus: {
      isLive: true,
      hasUpdates: false,
    },
    emptyInboxMessages: EMPTY_INBOX_MESSAGES,
    emptyInboxMessagesIndex: 0,
    activeFilter: {
      conversationFilter: {
        readStatus: "all",
        assignedTo: null,
      },
      conversationSearch: null,
      conversationSort: "desc",
    },
    conversations: [],
    sentimentConversations: [],
    conversationQueue: [],
    conversationsLoaded: false,
    creditBalance: null,
    loadNextConversations: null,
    lockedConversation: {
      contactId: null,
      inboxId: null,
      inboxType: null,
    },
    nextPageToken: null,
    selectedConversation: null,
    selectedConversationId: null,
    selectedConversationMessages: [],
    selectedConversationManuallyUnread: false,
    sentTollFreeBatches: [],
    sentBatches: [],
    totalBatches: [],
  }),
  actions: {
    [ACTIONS.ARCHIVE_CONVERSATION]({ commit, dispatch, state }, [value, ids]) {
      const inboxType = state.activeInbox.type;
      let request;

      if (inboxType === INBOX_TYPES.PERSONAL) {
        request = api.post(`/inbox/${state.activeInbox.userId}/archive`, {
          students: ids,
          archived: value,
        });
      }
      if (inboxType === INBOX_TYPES.GROUP) {
        request = api.post(
          `/groupaccounts/${state.activeInbox.groupId}/archive`,
          {
            students: ids,
            archived: value,
          }
        );
      }
      if (inboxType === INBOX_TYPES.HIGH_SPEED) {
        request = api.post(
          `/highspeedinbox/${state.activeInbox.highSpeedId}/archive`,
          {
            contacts: ids,
            archive: value,
          }
        );
      }

      request.then(() => {
        if (ids.includes(state.lockedConversation.contactId)) {
          dispatch(ACTIONS.UNLOCK_CONVERSATION);
        }
      });

      commit(MUTATIONS.ARCHIVE_CONVERSATION, ids);
    },

    [ACTIONS.MARK_AS_READ_CONVERSATION](
      { state },
      [value, ids, disableLoading]
    ) {
      const inboxType = state.activeInbox.type;
      const config = {
        headers: {
          "x-disable-loading": !!disableLoading,
        },
      };

      if (inboxType === INBOX_TYPES.PERSONAL) {
        api.post(
          `/inbox/${state.activeInbox.userId}/read`,
          {
            students: ids,
            wasRead: value,
          },
          config
        );
      }
      if (inboxType === INBOX_TYPES.GROUP) {
        api.post(
          `/groupaccounts/${state.activeInbox.groupId}/read`,
          {
            students: ids,
            wasRead: value,
          },
          config
        );
      }
      if (inboxType === INBOX_TYPES.HIGH_SPEED) {
        api.post(
          `/highspeedinbox/${state.activeInbox.highSpeedId}/read`,
          {
            contacts: ids,
            read: value,
          },
          config
        );
      }
    },

    [ACTIONS.BIND_CONVERSATION]({ dispatch, state }) {
      const source = INBOX_TYPE_SOURCES[state.activeInbox.type];
      if (source === INBOX_SOURCES.FIRESTORE)
        return dispatch(ACTIONS.BIND_FIRESTORE_CONVERSATION);

      if (source === INBOX_SOURCES.SIGNALR)
        return dispatch(ACTIONS.GET_TOLL_FREE_CONVERSATION);
    },

    [ACTIONS.BIND_CONVERSATIONS]({ dispatch, state }) {
      const source = INBOX_TYPE_SOURCES[state.activeInbox.type];

      if (source === INBOX_SOURCES.FIRESTORE) {
        const isSearch = !!state.activeFilter.conversationSearch;
        if (isSearch) {
          return dispatch(ACTIONS.SEARCH_FIRESTORE_CONVERSATIONS);
        }
        return dispatch(ACTIONS.BIND_FIRESTORE_CONVERSATIONS);
      }

      if (source === INBOX_SOURCES.SIGNALR) {
        return dispatch(ACTIONS.GET_TOLL_FREE_INBOX);
      }
    },

    [ACTIONS.BIND_NEXT_CONVERSATIONS]({ dispatch, state }, lastConversation) {
      let source = INBOX_TYPE_SOURCES[state.activeInbox.type];

      if (source === INBOX_SOURCES.FIRESTORE)
        return dispatch(
          ACTIONS.BIND_NEXT_FIRESTORE_CONVERSATIONS,
          lastConversation
        );

      if (source === INBOX_SOURCES.SIGNALR)
        return dispatch(ACTIONS.GET_NEXT_TOLL_FREE_CONVERSATIONS);
    },

    [ACTIONS.BIND_MESSAGES]({ dispatch, state }, id) {
      const source = INBOX_TYPE_SOURCES[state.activeInbox.type];

      if (source === INBOX_SOURCES.FIRESTORE)
        return dispatch(ACTIONS.BIND_FIRESTORE_MESSAGES, id);
    },

    [ACTIONS.GET_CREDIT_BALANCE]({ commit, state }) {
      if (creditBalanceTimeout) clearTimeout(creditBalanceTimeout);

      return getBalance();

      async function getBalance() {
        try {
          if (state.activeInbox.highSpeedId) {
            let response = await api.get(
              `/highspeedinbox/${state.activeInbox.highSpeedId}/creditbalance`,
              {
                headers: { "x-disable-loading": true },
              }
            );
            commit(MUTATIONS.SET_CREDIT_BALANCE, response.balance);
            creditBalanceTimeout = setTimeout(getBalance, 30000);
            return response.balance;
          }
        } catch (e) {
          return null;
        }
      }
    },

    [ACTIONS.LOAD_CONVERSATION_QUEUE]({ commit }) {
      commit(MUTATIONS.LOAD_CONVERSATION_QUEUE);
    },

    [ACTIONS.LOCK_CONVERSATION]({ commit, state }, disableLoading) {
      const inboxType = state.activeInbox.type;
      const config = {
        headers: {
          "x-disable-loading": !!disableLoading,
        },
      };
      let request = Promise.resolve();
      let contactId = state.selectedConversationId;
      let inboxId = null;

      if (!contactId) {
        return request;
      }

      if (inboxType === INBOX_TYPES.GROUP) {
        inboxId = state.activeInbox.groupId;
        request = api.post(
          `/groupaccounts/${inboxId}/lock`,
          {
            studentId: contactId,
          },
          config
        );
      } else if (inboxType === INBOX_TYPES.HIGH_SPEED) {
        inboxId = state.activeInbox.highSpeedId;
        request = api
          .post(`/highspeedinbox/${inboxId}/lock/${contactId}`, {}, config)
          .then((response) => {
            let state = response.state;
            state.lockDateUtc = state.lockDate;
            delete state.lockDate;
            return state;
          });
      }

      return request.then((response) => {
        commit(MUTATIONS.SET_LOCKED_CONVERSATION, {
          contactId: contactId,
          inboxId: inboxId,
          inboxType: inboxType,
        });
        return response;
      });
    },

    [ACTIONS.SELECT_CONVERSATION]({ commit, dispatch }, value) {
      dispatch(ACTIONS.UNLOCK_CONVERSATION);
      commit(MUTATIONS.SET_CONVERSATION_ID, value);
    },

    [ACTIONS.SELECT_NEXT_CONVERSATION]({ state, getters, dispatch }) {
      let conversations = getters[GETTERS.SORTED_CONVERSATIONS];
      let currentIndex = conversations.findIndex(
        (c) => c.id === state.selectedConversationId
      );
      let nextIndex =
        currentIndex === conversations.length - 1
          ? currentIndex - 1
          : currentIndex + 1;
      let nextId = conversations[nextIndex]
        ? conversations[nextIndex].id
        : null;

      return dispatch(ACTIONS.SELECT_CONVERSATION, nextId);
    },

    [ACTIONS.UNLOCK_CONVERSATION]({ commit, state }) {
      let request = Promise.resolve();

      if (state.lockedConversation.contactId === null) {
        return false;
      }

      if (state.lockedConversation.inboxType === INBOX_TYPES.GROUP) {
        request = api.post(
          `/groupaccounts/${state.lockedConversation.inboxId}/unlock`,
          {
            studentId: state.lockedConversation.contactId,
          }
        );
      } else if (
        state.lockedConversation.inboxType === INBOX_TYPES.HIGH_SPEED
      ) {
        request = api.post(
          `/highspeedinbox/${state.lockedConversation.inboxId}/unlock/${state.lockedConversation.contactId}`
        );
      }

      request.then(() => {
        commit(MUTATIONS.RESET_LOCKED_CONVERSATION);
      });
    },

    [ACTIONS.UPDATE_ACTIVE_INBOX]({ commit }, activeInbox) {
      commit(MUTATIONS.UPDATE_ACTIVE_INBOX, activeInbox);
      commit(MUTATIONS.UPDATE_ACTIVE_FILTER);
    },

    [ACTIONS.UPDATE_INBOX_FILTERS]({ commit }) {
      commit(MUTATIONS.UPDATE_INBOX_FILTERS);
    },

    [ACTIONS.UPDATE_ACTIVE_INBOX_FILTER]({ commit, state }, value) {
      commit(MUTATIONS.UPDATE_ACTIVE_INBOX_FILTER, {
        filter: value,
      });
      commit(MUTATIONS.UPDATE_INBOX_FILTERS, state.activeInbox.id);
    },

    [ACTIONS.UPDATE_ACTIVE_INBOX_SEARCH]({ commit }, value) {
      commit(MUTATIONS.UPDATE_ACTIVE_INBOX_SEARCH, value);
    },

    [ACTIONS.UPDATE_ACTIVE_INBOX_SORT]({ commit, state }, value) {
      commit(MUTATIONS.UPDATE_ACTIVE_INBOX_SORT, value);
      commit(MUTATIONS.UPDATE_INBOX_FILTERS, state.activeInbox.id);
    },

    [ACTIONS.UPDATE_INBOX_VIEW]({ commit }, isInboxView) {
      commit(MUTATIONS.UPDATE_INBOX_VIEW, isInboxView);
    },

    [ACTIONS.RESET_CONVERSATION]({ commit, dispatch, state }) {
      const source = INBOX_TYPE_SOURCES[state.activeInbox.type];

      if (source === INBOX_SOURCES.FIRESTORE) {
        dispatch(ACTIONS.RESET_FIRESTORE_CONVERSATION);
        dispatch(ACTIONS.UPDATE_SELECTED_CONVERSATION_MANUALLY_UNREAD, false);
      }
      if (source === INBOX_SOURCES.SIGNALR) {
        dispatch(ACTIONS.RESET_TOLL_FREE_CONVERSATION);
      }

      commit(MUTATIONS.SET_CONTACT, {});
      dispatch(ACTIONS.SELECT_CONVERSATION, null);
    },

    [ACTIONS.RESET_CONVERSATIONS]({ commit, dispatch, state }) {
      const source = INBOX_TYPE_SOURCES[state.activeInbox.type];

      if (source === INBOX_SOURCES.FIRESTORE)
        dispatch(ACTIONS.RESET_FIRESTORE_CONVERSATIONS);

      if (source === INBOX_SOURCES.SIGNALR)
        dispatch(ACTIONS.RESET_TOLL_FREE_INBOX);

      commit(MUTATIONS.CONVERSATIONS_LOADED, false);
    },

    [ACTIONS.UPDATE_SELECTED_CONVERSATION_MANUALLY_UNREAD]({ commit }, value) {
      commit(MUTATIONS.SET_SELECTED_CONVERSATION_MANUALLY_UNREAD, value);
    },

    [ACTIONS.BIND_FIRESTORE_CONVERSATION]: ({
      state,
      rootState,
      dispatch,
      commit,
    }) => {
      return new Promise((resolve) => {
        const isSearch = !!state.activeFilter.conversationSearch;
        let initialLoad = true,
          id = state.selectedConversationId,
          orgRef = `org-${rootState.config.orgId}-contacts`,
          query = firestore.app.collection(
            `${orgRef}/${state.activeInbox.documentKey}/conversations`
          );

        query = query.where("contactId", "==", id).limit(1);

        if (state.activeInbox.type === INBOX_TYPES.PERSONAL)
          query = query.where("departmentId", "==", rootState.config.team.id);

        if (typeof unsubscribeConversation === "function")
          unsubscribeConversation();

        unsubscribeConversation = query.onSnapshot((snapshot) => {
          let conversation;

          snapshot.forEach((doc) => {
            conversation = doc.data();
            conversation.id = doc.id;
          });

          if (initialLoad) {
            initialLoad = false;
            dispatch(ACTIONS.BIND_FIRESTORE_MESSAGES, conversation.id);
            if (lockedByUser(conversation, rootState.config.userId)) {
              dispatch(ACTIONS.LOCK_CONVERSATION);
            }
          }

          //check if message is incoming
          if (
            state.selectedConversation &&
            state.selectedConversation.date !== conversation.date
          ) {
            dispatch(
              ACTIONS.UPDATE_SELECTED_CONVERSATION_MANUALLY_UNREAD,
              false
            );
          }
          if (
            state.selectedConversation &&
            conversation.unread &&
            state.selectedConversation.id === conversation.id &&
            !state.selectedConversationManuallyUnread
          ) {
            dispatch(ACTIONS.MARK_AS_READ_CONVERSATION, [
              true,
              [state.selectedConversationId],
              true,
            ]);
          }

          commit(MUTATIONS.UPDATE_CONVERSATION, conversation);

          if (isSearch) {
            commit(MUTATIONS.UPDATE_FIRESTORE_SEARCH_RESULT, conversation);
          }

          resolve(conversation);
        });
      });
    },

    [ACTIONS.BIND_FIRESTORE_CONVERSATIONS]({
      state,
      dispatch,
      getters,
      rootState,
      commit,
    }) {
      let initialLoad = true,
        orderBy = state.activeFilter.conversationSort,
        showUnread =
          state.activeFilter.conversationFilter.readStatus == "unread",
        showMyContacts = state.activeFilter.conversationFilter.assignedTo,
        orgRef = `org-${rootState.config.orgId}-contacts`,
        query = firestore.app
          .collection(
            `${orgRef}/${state.activeInbox.documentKey}/conversations`
          )
          .where("archived", "==", state.isArchivedView)
          .where("inbox", "==", state.isInboxView)
          .orderBy("date", orderBy)
          .limit(conversationLimit);

      if (state.activeInbox.type === INBOX_TYPES.PERSONAL)
        query = query.where("departmentId", "==", rootState.config.team.id);

      if (state.activeFilter.conversationFilter.readStatus !== "all")
        query = query.where("unread", "==", showUnread);

      if (showMyContacts) {
        query = query.where(
          "assignedTo",
          "==",
          state.activeFilter.conversationFilter.assignedTo
        );
      }

      let unsubscribe = query.onSnapshot((snapshot) => {
        let conversations = [];

        if (initialLoad) {
          initialLoad = false;
          snapshot.docChanges().forEach((change) => {
            let item = change.doc.data();
            item.id = item.contactId;

            conversations.push(item);
          });

          commit(MUTATIONS.SET_CONVERSATIONS, conversations);
          if (getters[GETTERS.FEATURE_ENABLED]("poc-sms-sentiment-analysis")) {
            dispatch(ACTIONS.UPDATE_SENTIMENT_CONVERSATIONS);
          }
          commit(MUTATIONS.CONVERSATIONS_LOADED, true);
        } else {
          snapshot.docChanges().forEach((change) => {
            const { newIndex, doc, type } = change;
            let item = doc.data();
            item.id = item.contactId;

            commit(MUTATIONS.UPDATE_FIRESTORE_CONVERSATIONS, {
              newIndex,
              item,
              type,
            });
          });
        }
      });

      conversationListeners.push(unsubscribe);
    },

    async [ACTIONS.UPDATE_SENTIMENT_CONVERSATIONS]({
      state,
      rootState,
      commit,
    }) {
      let sentimentPromises = [];

      for (const conversation of state.conversations) {
        sentimentPromises.push(
          api.post("/gpt/sentiment/contact", {
            ContactId: conversation.contactId,
            RatingGranularity: 9,
            StaffId: rootState.config.userId,
          })
        );
      }

      const results = await Promise.all(sentimentPromises);

      const ratedConversations = state.conversations.reduce((acc, cur, i) => {
        const ratingResult = results[i];

        return [
          ...acc,
          {
            ...cur,
            sentimentRating: ratingResult.isSuccess
              ? ratingResult.rating
              : null,
          },
        ];
      }, []);

      commit(MUTATIONS.SET_SENTIMENT_CONVERSATIONS, ratedConversations);
    },

    [ACTIONS.BIND_NEXT_FIRESTORE_CONVERSATIONS](
      { state, rootState, commit },
      lastConversation
    ) {
      commit(MUTATIONS.SET_NEXT_CONVERSATIONS_LOADING_STATE, true);
      let initialLoad = true,
        orderBy = state.activeFilter.conversationSort,
        showUnread =
          state.activeFilter.conversationFilter.readStatus == "unread",
        showMyContacts = state.activeFilter.conversationFilter.assignedTo,
        orgRef = `org-${rootState.config.orgId}-contacts`,
        query = firestore.app
          .collection(
            `${orgRef}/${state.activeInbox.documentKey}/conversations`
          )
          .where("archived", "==", state.isArchivedView)
          .where("inbox", "==", state.isInboxView)
          .orderBy("date", orderBy)
          .startAfter(lastConversation)
          .limit(conversationLimit);

      if (state.activeInbox.type === INBOX_TYPES.PERSONAL) {
        query = query.where("departmentId", "==", rootState.config.team.id);
      }

      if (state.activeFilter.conversationFilter.readStatus !== "all") {
        query = query.where("unread", "==", showUnread);
      }

      if (showMyContacts) {
        query = query.where(
          "assignedTo",
          "==",
          state.activeFilter.conversationFilter.assignedTo
        );
      }

      let unsubscribe = query.onSnapshot((snapshot) => {
        let nextConversations = [];
        if (initialLoad) {
          initialLoad = false;
          snapshot.docChanges().forEach((change) => {
            let item = change.doc.data();
            item.id = item.contactId;

            nextConversations.push(item);
          });

          commit(MUTATIONS.SET_NEXT_CONVERSATIONS_LOADING_STATE, false);

          if (nextConversations.length) {
            commit(MUTATIONS.SET_NEXT_CONVERSATIONS, nextConversations);
          }
        } else {
          snapshot.docChanges().forEach((change) => {
            const { newIndex, doc, type } = change;
            let item = doc.data();
            item.id = item.contactId;

            commit(MUTATIONS.UPDATE_FIRESTORE_CONVERSATIONS, {
              newIndex,
              item,
              type,
            });
          });
        }
      });
      conversationListeners.push(unsubscribe);
    },

    [ACTIONS.BIND_FIRESTORE_MESSAGES]: firestoreAction(
      ({ state, rootState, bindFirestoreRef }, id) => {
        let orgRef = `org-${rootState.config.orgId}-contacts`;

        return bindFirestoreRef(
          "selectedConversationMessages",
          firestore.app
            .collection(
              `${orgRef}/${state.activeInbox.documentKey}/conversations/${id}/messages`
            )
            .orderBy("date", "asc")
        );
      }
    ),

    [ACTIONS.RESET_FIRESTORE_CONVERSATION]: firestoreAction(
      ({ unbindFirestoreRef }) => {
        if (typeof unsubscribeConversation === "function")
          unsubscribeConversation();
        unbindFirestoreRef("selectedConversationMessages");
      }
    ),

    [ACTIONS.RESET_FIRESTORE_CONVERSATIONS]({ commit }) {
      conversationListeners.forEach((unsubscribe) => {
        unsubscribe();
      });
      conversationListeners = [];
      commit(MUTATIONS.RESET_CONVERSATIONS);
    },

    async [ACTIONS.SEARCH_FIRESTORE_CONVERSATIONS]({
      commit,
      dispatch,
      state,
    }) {
      let params = {
        archived: state.isArchivedView,
        filter: state.activeFilter.conversationFilter.readStatus,
        assignedTo: state.activeFilter.conversationFilter.assignedTo,
        limit: conversationLimit,
        sort: state.activeFilter.conversationSort,
        searchText: state.activeFilter.conversationSearch,
      };
      let path = `/inbox/conversationsearch`;

      if (state.activeInbox.type === INBOX_TYPES.GROUP) {
        path = `/groupAccounts/${state.activeInbox.groupId}/conversationsearch`;
      }

      const response = await api.get(path, { params });
      const conversations = response.map((c) => {
        c.id = c.contactId;
        return c;
      });

      commit(MUTATIONS.SET_CONVERSATIONS, conversations);
      commit(MUTATIONS.CONVERSATIONS_LOADED, true);

      if (conversations.length === 1) {
        dispatch(ACTIONS.SELECT_CONVERSATION, conversations[0].id);
      }
    },

    [ACTIONS.SHUFFLE_EMPTY_INBOX_MESSAGES]({ commit, state }) {
      let shuffledMessages = _.shuffle(state.emptyInboxMessages);
      commit(MUTATIONS.SET_EMPTY_INBOX_MESSAGES, shuffledMessages);
    },

    [ACTIONS.INCREMENT_EMPTY_INBOX_MESSAGES_INDEX]({ commit, state }) {
      let index = state.emptyInboxMessagesIndex;
      index = index < state.emptyInboxMessages.length - 1 ? index + 1 : 0;
      commit(MUTATIONS.SET_EMPTY_INBOX_MESSAGES_INDEX, index);
    },

    [ACTIONS.UPDATE_IS_INBOX_LIVE]({ commit }, isLive) {
      commit(MUTATIONS.UPDATE_IS_INBOX_LIVE, isLive);
    },

    [ACTIONS.PAUSE_LIVE_INBOX]({ commit }) {
      commit(MUTATIONS.PAUSE_LIVE_INBOX);
    },

    [ACTIONS.RESUME_LIVE_INBOX]({ commit }) {
      commit(MUTATIONS.RESUME_LIVE_INBOX);
    },

    async [ACTIONS.GET_TOLL_FREE_CONVERSATION]({
      commit,
      dispatch,
      state,
      rootState,
    }) {
      const id = state.selectedConversationId;
      const response = await api.get(
        `/highspeedinbox/${state.activeInbox.highSpeedId}/conversation/${id}`
      );
      const messages = response.map((m) => {
        m.pending = m.direction === "outgoing" && m.status === "Pending";
        m.error = m.errorMessage;
        return m;
      });

      let conversation = state.conversations.find(
        (c) => c.id === state.selectedConversationId
      );
      if (!conversation) {
        conversation = await api.get(
          `/highspeedinbox/${state.activeInbox.highSpeedId}/conversations/${id}`
        );
        conversation = formatSignalRConversation(conversation);
      }

      commit(MUTATIONS.SET_TOLL_FREE_MESSAGES, messages);
      commit(MUTATIONS.UPDATE_CONVERSATION, conversation);

      if (lockedByUser(conversation, rootState.config.userId)) {
        dispatch(ACTIONS.LOCK_CONVERSATION);
      }

      if (
        signalRMessagesConnection &&
        signalRMessagesConnection.state === signalR.HubConnectionState.Connected
      ) {
        signalRMessagesConnection.send("leavegroup", signalRMessageGroup);
      }

      signalRMessagesConnection = signalR.connectToHub(
        "highspeedconversationws"
      );

      signalRMessagesConnection.on("receive", function (change) {
        change.pending =
          change.direction === "outgoing" && change.status === "Pending";
        change.error = change.errorMessage;
        commit(MUTATIONS.ADD_TOLL_FREE_MESSAGE, change);
        if (
          state.selectedConversation &&
          state.selectedConversation.id === conversation.id
        ) {
          dispatch(ACTIONS.MARK_AS_READ_CONVERSATION, [
            true,
            [conversation.id],
          ]);
        }
      });

      signalRMessagesConnection.on("deliveryStatus", function (change) {
        commit(MUTATIONS.UPDATE_TOLL_FREE_MESSAGE_STATUS, change);
      });

      signalRMessagesConnection.on("campaignUpdate", function (change) {
        commit(MUTATIONS.UPDATE_CAMPAIGN, change);
      });

      signalRMessagesConnection.start().then(() => {
        signalRMessageGroup = `high_speed_${state.activeInbox.highSpeedId}_contact_${state.selectedConversationId}`;
        if (
          signalRMessagesConnection.state ===
          signalR.HubConnectionState.Connected
        ) {
          signalRMessagesConnection.send("joingroup", signalRMessageGroup);
        }
      });

      return conversation;
    },

    async [ACTIONS.GET_TOLL_FREE_INBOX]({ commit, dispatch, getters, state }) {
      const isSearch = !!state.activeFilter.conversationSearch;
      let params = {
        archived: state.isArchivedView,
        filter: state.activeFilter.conversationFilter.readStatus,
        limit: conversationLimit,
        sort: state.activeFilter.conversationSort,
      };
      let resource = "conversations";

      if (isSearch) {
        params.searchText = state.activeFilter.conversationSearch;
        resource = "search";
      }

      const response = await api.get(
        `/highspeedinbox/${state.activeInbox.highSpeedId}/${resource}`,
        { params }
      );
      const conversations = response.data.map((c) => {
        return formatSignalRConversation(c);
      });

      commit(MUTATIONS.SET_CONVERSATIONS, conversations);
      commit(MUTATIONS.CONVERSATIONS_LOADED, true);
      commit(MUTATIONS.SET_NEXT_PAGE_TOKEN, response.nextPageToken);

      if (isSearch && conversations.length === 1) {
        dispatch(ACTIONS.SELECT_CONVERSATION, conversations[0].id);
      }

      if (signalRTollFreeInboxConnection) signalRTollFreeInboxConnection.stop();

      signalRTollFreeInboxConnection = signalR.connectToHub("highspeedinboxws");

      signalRTollFreeInboxConnection.on("receive", function (change) {
        let { changeEvent, conversation, event } = change;

        conversation = formatSignalRConversation(conversation);
        change = { changeEvent, conversation, event };

        if (getters[GETTERS.FEATURE_ENABLED]("inbox-controls")) {
          if (state.inboxStatus.isLive === true) {
            commit(MUTATIONS.UPDATE_TOLL_FREE_INBOX_LIVE, change);
          } else {
            commit(MUTATIONS.UPDATE_TOLL_FREE_INBOX_PAUSED, change);
          }
        } else {
          commit(MUTATIONS.UPDATE_TOLL_FREE_INBOX, change);
        }
      });

      signalRTollFreeInboxConnection.start().then(() => {
        const group = `high_speed_inbox_${state.activeInbox.highSpeedId}`;
        signalRTollFreeInboxConnection.send("joingroup", group);
      });
    },

    [ACTIONS.RESET_TOLL_FREE_CONVERSATION]({ commit }) {
      commit(MUTATIONS.UPDATE_CONVERSATION, null);
      commit(MUTATIONS.SET_TOLL_FREE_MESSAGES, []);
    },

    [ACTIONS.RESET_TOLL_FREE_INBOX]({ commit }) {
      if (signalRTollFreeInboxConnection) signalRTollFreeInboxConnection.stop();

      if (signalRMessagesConnection) signalRMessagesConnection.stop();

      if (creditBalanceTimeout) clearTimeout(creditBalanceTimeout);

      commit(MUTATIONS.RESET_CONVERSATIONS);
    },

    async [ACTIONS.GET_NEXT_TOLL_FREE_CONVERSATIONS]({ commit, state }) {
      if (!state.nextPageToken) {
        return;
      }

      let params = {
        archived: state.isArchivedView,
        filter: state.activeFilter.conversationFilter.readStatus,
        limit: conversationLimit,
        pagetoken: state.nextPageToken,
        sort: state.activeFilter.conversationSort,
      };
      let resource = "conversations";

      if (state.activeFilter.conversationSearch) {
        params.searchText = state.activeFilter.conversationSearch;
        resource = "search";
      }

      commit(MUTATIONS.SET_NEXT_CONVERSATIONS_LOADING_STATE, true);

      const response = await api.get(
        `/highspeedinbox/${state.activeInbox.highSpeedId}/${resource}`,
        { params }
      );
      const nextConversations = response.data.map((c) => {
        return formatSignalRConversation(c);
      });
      commit(MUTATIONS.SET_NEXT_PAGE_TOKEN, response.nextPageToken);
      commit(MUTATIONS.SET_NEXT_CONVERSATIONS_LOADING_STATE, false);
      if (nextConversations.length) {
        commit(MUTATIONS.SET_NEXT_CONVERSATIONS, nextConversations);
      }
    },

    async [ACTIONS.GET_TOLL_FREE_SENT]({ commit, state }) {
      const sentTollFreeBatches = await api.get(
        `/highspeedinbox/${state.activeInbox.highSpeedId}/batches`
      );
      commit(MUTATIONS.SET_TOLL_FREE_SENT, sentTollFreeBatches);
    },

    async [ACTIONS.GET_INBOX_SENT]({ commit, state }, options) {
      const inboxType = state.activeInbox.type;
      let sentBatches;

      if (inboxType === INBOX_TYPES.PERSONAL) {
        sentBatches = await api.get(`/inboxbatch/batches`, options);
      } else if (inboxType === INBOX_TYPES.GROUP) {
        sentBatches = await api.get(
          `/inboxbatch/${state.activeInbox.groupId}/batches`,
          options
        );
      }

      // there truly has to be a less stupid way to do this
      sentBatches = mapKeysDeep(sentBatches, (value, key) => {
        return decapitalize(key);
      });
      commit(
        MUTATIONS.SET_INBOX_SENT,
        arrayToHashMap(sentBatches.results, "id")
      );
      commit(MUTATIONS.SET_TOTAL_BATCHES, sentBatches.meta.total);
    },

    async [ACTIONS.START_SIGNALR_INBOX_SENT]({ rootState, state, commit }) {
      if (signalRInboxConnection) signalRInboxConnection.stop();

      signalRInboxConnection = signalR.connectToHub("outgoingbatchws");

      signalRInboxConnection.on("outgoingBatchUpdated", function (change) {
        let batchExists = hashGet(state.sentBatches, change.id);

        if (batchExists) {
          const newBatch = mapKeysDeep(change, (value, key) => {
            return decapitalize(key);
          });

          let newBatches = hashCloneDeep(state.sentBatches);
          hashMerge(newBatches, newBatch.id, newBatch);

          commit(MUTATIONS.SET_INBOX_SENT, newBatches);
        } else {
          const newBatch = mapKeysDeep(change, (value, key) => {
            return decapitalize(key);
          });

          let newBatchesArray = hashValues(state.sentBatches);
          newBatchesArray.unshift(newBatch);

          commit(
            MUTATIONS.SET_INBOX_SENT,
            arrayToHashMap(newBatchesArray, "id")
          );
        }
      });

      signalRInboxConnection.start().then(() => {
        const inboxType = state.activeInbox.type;
        if (inboxType === INBOX_TYPES.PERSONAL) {
          signalRInboxConnection.send(
            "JoinPersonalInbox",
            rootState.config.userId
          );
        } else if (inboxType === INBOX_TYPES.GROUP) {
          signalRInboxConnection.send(
            "JoinSharedInbox",
            state.activeInbox.groupId
          );
        }
      });
    },
  },
  getters: {
    [GETTERS.GET_INBOX_LAST_MESSAGE_TIME_AGO]: (state, getters, rootState) => {
      return state.selectedConversation
        ? moment
            .tz(
              moment.utc(state.selectedConversation.date),
              rootState.config.timeZone
            )
            .subtract(5, "seconds")
            .fromNow()
        : "";
    },
    [GETTERS.MESSAGE_COUNT]: (state) => {
      return state.selectedConversationMessages.length;
    },
    [GETTERS.SHARED_INBOX_BY_ID]: (state, getters, rootState) => (id) => {
      if (!rootState.config.sharedInboxes) return;
      let sharedInbox = rootState.config.sharedInboxes.find(
        (inbox) => parseInt(inbox.id) === parseInt(id)
      );
      return sharedInbox;
    },

    [GETTERS.SORTED_CONVERSATIONS]: (state) => {
      return _.sortByOrder(
        state.conversations,
        ["date"],
        [state.activeFilter.conversationSort]
      );
    },

    [GETTERS.SMS_CONVERSATIONS_SENTIMENT_SORTED]: (state) => {
      return _.sortByOrder(
        state.sentimentConversations,
        ["date"],
        [state.activeFilter.conversationSort]
      );
    },

    [GETTERS.NO_CONVERSATIONS]: (state) => {
      return state.conversationsLoaded && !state.conversations.length;
    },
  },
  mutations: {
    [MUTATIONS.ARCHIVE_CONVERSATION](state, ids) {
      ids.forEach((id) => {
        let conversation = state.conversations.find((c) => c.id === id);
        if (conversation) {
          conversation.removed = true;
        }
      });
    },

    [MUTATIONS.LOAD_CONVERSATION_QUEUE](state) {
      state.conversationQueue.forEach((item) => {
        state.conversations.push(item);
      });
      state.conversationQueue = [];
    },

    [MUTATIONS.RESET_LOCKED_CONVERSATION](state) {
      state.lockedConversation = {
        contactId: null,
        inboxId: null,
        inboxType: null,
      };
    },

    [MUTATIONS.SET_CONVERSATION_ID](state, id) {
      state.selectedConversationId = id;
    },

    [MUTATIONS.SET_CONVERSATIONS](state, conversations) {
      state.conversations = conversations;
    },

    [MUTATIONS.SET_SENTIMENT_CONVERSATIONS](state, conversations) {
      state.sentimentConversations = conversations;
    },

    [MUTATIONS.SET_CREDIT_BALANCE](state, payload) {
      state.creditBalance = payload;
    },

    [MUTATIONS.SET_LOCKED_CONVERSATION](state, payload) {
      state.lockedConversation = payload;
    },

    [MUTATIONS.SET_NEXT_PAGE_TOKEN](state, token) {
      state.nextPageToken = token;
    },

    [MUTATIONS.UPDATE_ACTIVE_INBOX](state, activeInbox) {
      state.activeInbox = activeInbox;
    },

    [MUTATIONS.UPDATE_INBOX_FILTERS](state) {
      const filterKey = getActiveInboxFilterKey(state.activeInbox);
      const filterIndex = state.inboxFilters.findIndex((filter) =>
        _.has(filter, filterKey)
      );

      let inboxFilter = {};
      if (filterIndex === -1) {
        inboxFilter[filterKey] = state.activeFilter;
        state.inboxFilters.push(inboxFilter);
      } else {
        inboxFilter[filterKey] = state.activeFilter;
        state.inboxFilters[filterIndex] = inboxFilter;
      }
    },

    [MUTATIONS.UPDATE_ACTIVE_FILTER](state) {
      const filterKey = getActiveInboxFilterKey(state.activeInbox);
      const filterIndex = state.inboxFilters.findIndex((filter) =>
        _.has(filter, filterKey)
      );

      if (filterIndex === -1) {
        state.activeFilter = {
          conversationFilter: {
            readStatus: "all",
            assignedTo: false,
          },
          conversationSearch: null,
          conversationSort: "desc",
        };
      } else {
        state.activeFilter = state.inboxFilters[filterIndex][filterKey];
      }
    },

    [MUTATIONS.UPDATE_CONVERSATION](state, conversation) {
      state.selectedConversation = conversation;
    },

    [MUTATIONS.UPDATE_ACTIVE_INBOX_FILTER](state, { filter }) {
      if (filter.value === "unread" || filter.value === "read") {
        if (state.activeFilter.conversationFilter.readStatus === filter.value) {
          state.activeFilter.conversationFilter.readStatus = "all";
        } else {
          state.activeFilter.conversationFilter.readStatus = filter.value;
        }
      }
      if (filter.value === "all") {
        state.activeFilter.conversationFilter.readStatus = "all";
      }
      if (filter.value === "assigned to me") {
        state.activeFilter.conversationFilter.assignedTo =
          state.activeFilter.conversationFilter.assignedTo ===
          filter.filterUserId
            ? null
            : filter.filterUserId;
      }
      if (filter.value === "assigned to") {
        state.activeFilter.conversationFilter.assignedTo = filter.filterUserId;
      }
    },

    [MUTATIONS.UPDATE_ACTIVE_INBOX_SEARCH](state, searchText) {
      state.activeFilter.conversationSearch = searchText ? searchText : null;
    },

    [MUTATIONS.UPDATE_ACTIVE_INBOX_SORT](state, sort) {
      state.activeFilter.conversationSort = sort;
    },

    [MUTATIONS.UPDATE_INBOX_VIEW](state, isInboxView) {
      state.isArchivedView = !isInboxView;
      state.isInboxView = isInboxView;
    },

    [MUTATIONS.RESET_CONVERSATIONS](state) {
      state.conversations = [];
      state.conversationQueue = [];
      state.inboxStatus.hasUpdates = false;
    },

    [MUTATIONS.UPDATE_FIRESTORE_CONVERSATIONS](
      state,
      { newIndex, item, type }
    ) {
      const existingConversation = state.conversations.find(
        (c) => c.id === item.id
      );
      const queuedConversation = state.conversationQueue.find(
        (c) => c.id === item.id
      );
      const targetConversation = existingConversation || queuedConversation;

      if (type === "added") {
        if (targetConversation) {
          if (targetConversation.removed) {
            delete targetConversation.removed;
          }
          return Object.assign(targetConversation, item);
        }

        if (newIndex <= state.conversations.length - 1) {
          state.conversationQueue.push(item);
        } else {
          state.conversations.push(item);
        }
      }

      if (type === "modified") {
        if (targetConversation) {
          Object.assign(targetConversation, item);
        }
      }

      if (type === "removed") {
        const updateOrRemoveItem = function (conversations, conversation) {
          const isUnreadView =
            state.activeFilter.conversationFilter.readStatus ===
            CONVERSATION_FILTERS.UNREAD;
          const isSelectedConversation =
            state.selectedConversationId === item.id;
          const isUnreadUpdate = isUnreadView && isSelectedConversation;

          if (isUnreadUpdate) {
            conversation.unread = false;
          } else {
            const index = conversations.findIndex((c) => c.id === item.id);
            conversations.splice(index, 1);
          }
        };

        if (existingConversation) {
          updateOrRemoveItem(state.conversations, existingConversation);
        }
        if (queuedConversation) {
          updateOrRemoveItem(state.conversationQueue, queuedConversation);
        }
      }
    },

    [MUTATIONS.UPDATE_FIRESTORE_SEARCH_RESULT](state, conversation) {
      conversation.id = conversation.contactId;

      let selectedConversation = state.conversations.find(
        (c) => c.id === conversation.id
      );
      Object.assign(selectedConversation, {
        date: conversation.date,
        media: conversation.media,
        message: conversation.message,
        state: conversation.state,
        unread: conversation.unread,
      });
    },

    [MUTATIONS.ADD_TOLL_FREE_MESSAGE](state, message) {
      state.selectedConversationMessages.push(message);
    },

    [MUTATIONS.SET_TOLL_FREE_MESSAGES](state, messages) {
      state.selectedConversationMessages = messages;
    },

    [MUTATIONS.SET_NEXT_CONVERSATIONS](state, nextConversations) {
      let conversations = state.conversations;
      state.conversations = conversations.concat(nextConversations);
    },

    [MUTATIONS.SET_NEXT_CONVERSATIONS_LOADING_STATE](state, status) {
      state.loadNextConversations = status;
    },

    [MUTATIONS.UPDATE_TOLL_FREE_INBOX](state, change) {
      let { conversation, event } = change;
      const isSearch = !!state.activeFilter.conversationSearch;

      if (event === "New") {
        if (isSearch) {
          return;
        }
        state.conversationQueue.push(conversation);
      }

      if (event === "Updated") {
        let existingConversation = state.conversations.find(
          (c) => c.id === conversation.id
        );
        let queuedConversation = state.conversationQueue.find(
          (c) => c.id === conversation.id
        );
        const addToQueue =
          state.isInboxView === !conversation.archived &&
          !isSearch &&
          !existingConversation &&
          !queuedConversation;
        const isSelectedConversation =
          state.selectedConversationId === conversation.id;

        if (isSelectedConversation) {
          state.selectedConversation = conversation;
        }

        if (addToQueue) {
          state.conversationQueue.push(conversation);
          return;
        }

        const updateOrRemoveItem = function (
          conversations,
          targetConversation
        ) {
          const isUpdate =
            targetConversation.archived === conversation.archived;
          if (isUpdate) {
            Object.assign(targetConversation, conversation);
          } else {
            const index = conversations.findIndex(
              (c) => c.id === conversation.id
            );
            conversations.splice(index, 1);
          }
        };

        if (existingConversation) {
          updateOrRemoveItem(state.conversations, existingConversation);
        } else if (queuedConversation) {
          updateOrRemoveItem(state.conversationQueue, queuedConversation);
        }
      }
    },

    [MUTATIONS.UPDATE_TOLL_FREE_INBOX_LIVE](state, change) {
      let { conversation, event } = change;
      const isSearch = !!state.activeFilter.conversationSearch;

      if (event === "New") {
        if (isSearch) {
          return;
        }
        state.conversation.push(conversation);
      }

      if (event === "Updated") {
        let existingConversation = state.conversations.find(
          (c) => c.id === conversation.id
        );
        const isInsert =
          state.isInboxView === !conversation.archived &&
          !isSearch &&
          !existingConversation;
        const isSelectedConversation =
          state.selectedConversationId === conversation.id;

        if (isSelectedConversation) {
          state.selectedConversation = conversation;
        }

        if (isInsert) {
          state.conversations.push(conversation);
          return;
        }

        if (existingConversation) {
          const isUpdate =
            existingConversation.archived === conversation.archived;
          const isRemove = !isUpdate;

          if (isUpdate) {
            Object.assign(existingConversation, conversation);
          }
          if (isRemove) {
            const index = state.conversations.findIndex(
              (c) => c.id === conversation.id
            );
            state.conversations.splice(index, 1);
          }
        }
      }
    },

    [MUTATIONS.UPDATE_TOLL_FREE_INBOX_PAUSED](state, change) {
      let { changeEvent, conversation, event } = change;
      const isSearch = !!state.filters.conversationSearch;
      const hasUpdates = (value) => {
        state.inboxStatus.hasUpdates = value;
      };

      if (event === "New") {
        hasUpdates(true);
      }

      if (event === "Updated") {
        let existingConversation = state.conversations.find(
          (c) => c.id === conversation.id
        );
        const isInsert =
          state.isInboxView === !conversation.archived &&
          !isSearch &&
          !existingConversation;
        const isSelectedConversation =
          state.selectedConversationId === conversation.id;

        if (isSelectedConversation) {
          state.selectedConversation = conversation;
        }

        if (isInsert) {
          hasUpdates(true);
          return;
        }

        if (existingConversation) {
          const isUpdate =
            existingConversation.archived === conversation.archived;
          const isRemove = !isUpdate;

          if (isUpdate) {
            // const validUpdatesWhenPaused = ["Read", "Unread", "Lock", "Unlock"]; // OutgoingMessage,IncomingMessage,StatusChange
            // if (validUpdatesWhenPaused.includes(changeEvent.change)) {
            //   delete conversation.date;
            //   Object.assign(existingConversation, conversation);
            // } else {
            //   hasUpdates(true);
            // }
            delete conversation.date;
            Object.assign(existingConversation, conversation);

            const validUpdatesWhenPaused = ["Read", "Unread", "Lock", "Unlock"];
            if (!validUpdatesWhenPaused.includes(changeEvent.change)) {
              hasUpdates(true);
            }
          }
          if (isRemove) {
            // still remove it? probably or might do double work if someone else removed it
            const index = state.conversations.findIndex(
              (c) => c.id === conversation.id
            );
            state.conversations.splice(index, 1);
          }
        }
      }
    },

    [MUTATIONS.UPDATE_TOLL_FREE_MESSAGE_STATUS](state, change) {
      let message = state.selectedConversationMessages.find(
        (m) => m.messageId === change.messageId
      );
      if (!message) return;
      message.pending =
        change.status === "DeliveryConfirmed" ||
        change.status === "DeliveryConfirmedReceipt"
          ? false
          : true;
    },

    [MUTATIONS.SET_TOLL_FREE_SENT](state, sentTollFreeBatches) {
      state.sentTollFreeBatches = sentTollFreeBatches;
    },

    [MUTATIONS.SET_INBOX_SENT](state, sentBatches) {
      state.sentBatches = sentBatches;
    },

    [MUTATIONS.SET_TOTAL_BATCHES](state, total) {
      state.totalBatches = total;
    },

    [MUTATIONS.CONVERSATIONS_LOADED](state, status) {
      state.conversationsLoaded = status;
    },

    [MUTATIONS.SET_EMPTY_INBOX_MESSAGES](state, messages) {
      state.emptyInboxMessages = messages;
    },

    [MUTATIONS.SET_EMPTY_INBOX_MESSAGES_INDEX](state, index) {
      state.emptyInboxMessagesIndex = index;
    },

    [MUTATIONS.SET_SELECTED_CONVERSATION_MANUALLY_UNREAD](state, value) {
      state.selectedConversationManuallyUnread = value;
    },

    [MUTATIONS.PAUSE_LIVE_INBOX](state) {
      state.inboxStatus.isLive = false;
    },

    [MUTATIONS.RESUME_LIVE_INBOX](state) {
      state.inboxStatus.isLive = true;
    },

    ...vuexfireMutations,
  },
};
